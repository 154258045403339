import React, { useState, useLayoutEffect, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import searchIcon from 'img/icon/search.svg'
import ParseContent from 'components/shared/ParseContent'

// Components
import ButtonMinimal from 'components/elements/ButtonMinimal'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'
import { useInView } from 'react-hook-inview'
import { motion } from 'framer-motion'
import Search from './Search'

const StyledCases = styled.div``

const Post = styled(motion.div)``

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
`

const ImgContainer = styled.div`
  height: 200px;
  width: 300px;
`

const PostContent = styled(motion.div)`
  width: 300px;
  border-radius: 20px;

  padding: 30px;
`

const Description = styled.div`
  min-height: 90px;

  p {
    font-size: ${(props) => props.theme.font.size.s};
  }
`

const PostWrapper = styled.div`
  position: relative;
  top: -20px;
`

const InputWrapper = styled.div`
  position: relative;
`

const Input = styled.input`
  background: ${(props) => props.theme.color.face.light};
  border-radius: 29.5px;
  z-index: 2;
  position: relative;
  border: none;
  padding: 0 10px 0 45px;
  height: 35px;
  width: 300px;
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.font.size.l};

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
    font-size: ${(props) => props.theme.font.size.l};
  }
`

const InputShadow = styled.div`
  position: absolute;
  height: calc(100% + 6px);
  width: calc(100% + 6px);
  top: -3px;
  left: -3px;
  z-index: 1;
  border-radius: 29.5px;
  background: ${(props) => props.theme.color.gradient.main};
`

const InnerPost = styled.div``

const StyledSearchIcon = styled.img`
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 3;
  height: 26px;
  width: 26px;
`

const FilterButton = styled.button`
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 30%);
  height: 40px;
  padding: 0 20px;
  position: relative;
  text-transform: capitalize;
  margin: 0.5rem 1rem;

  @media (max-width: 575px) {
    width: 40%;
  }
`

const SearchWrapper = styled.div`
  border-bottom: 2px solid #0cbdd1;
`

const Projects = ({ className }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.3,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if (isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  const {
    projects: { nodes: projects },
  } = useStaticQuery(graphql`
    {
      projects: allWordpressWpGemeentes(sort: { fields: date, order: DESC }) {
        nodes {
          id
          slug
          path
          title
          acf {
            province
          }
        }
      }
    }
  `)

  const provinces = [
    'groningen',
    'friesland',
    'drenthe',
    'overijssel',
    'flevoland',
    'gelderland',
    'utrecht',
    'noord-holland',
    'zuid-holland',
    'zeeland',
    'noord-brabant',
    'limburg',
  ]

  const [currentProvince, setCurrentProvince] = useState('')
  const [regions, setRegions] = useState(projects)

  const handleProvinceClick = (province) => {
    if (currentProvince === province) {
      setCurrentProvince('')
    } else {
      setCurrentProvince(province)
    }
  }

  useEffect(() => {
    let newRegions = projects

    if (currentProvince) {
      newRegions = projects.filter((project) => {
        if (project.acf.province) {
          return (
            project.acf.province.toLowerCase().replace(/\s+/g, '-') ===
            currentProvince.replace(/\s+/g, '-')
          )
        }
      })
    }

    setRegions(newRegions)
  }, [currentProvince])

  return (
    <StyledCases
      ref={ref}
      className={`container ${className ? `${className}` : ``}`}
    >
      <SearchWrapper>
        <Search onlyGemeentes />
      </SearchWrapper>
      <div ref={ref} className="mb-5 mt-4">
        <>
          <h2 className="w-100 font-weight-bold text-center mb-4">
            Selecteer een provincie
          </h2>

          <div className="mb-5 pb-4 d-flex justify-content-between justify-content-lg-center flex-wrap w-100 text-center">
            {provinces.map((province) => {
              return (
                <FilterButton
                  style={
                    currentProvince === province
                      ? {
                          background:
                            'linear-gradient( 90deg,rgba(114,57,149,1) 0%,rgba(11,189,209,1) 100% )',
                          color: 'white',
                          fontWeight: 'bold',
                        }
                      : {
                          background: 'white',
                        }
                  }
                  onClick={() => handleProvinceClick(province)}
                >
                  {province}
                </FilterButton>
              )
            })}
          </div>
        </>

        {currentProvince === '' ? (
          <h2 className="w-100 font-weight-bold text-center my-4 pb-3">
            Alle gemeente's
          </h2>
        ) : (
          <h2 className="w-100 font-weight-bold text-center my-4 pb-3">
            <span>Gemeentes in </span>
            <span style={{ textTransform: 'capitalize' }}>
              {currentProvince}
            </span>
          </h2>
        )}
        <div className="row">
          {regions.map((singleCase, index) => (
            <Post
              key={singleCase.wordpress_id}
              className="col-lg-4 pb-5 d-flex justify-content-center"
              whileHover="hover"
              initial="rest"
              // animate={isVisible ? 'init' : 'rest'}
              variants={{
                hover: {},
                rest: {
                  opacity: 1,
                  y: 30,
                },
                init: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: index * 0.2,
                    type: 'spring',
                    duration: 0.8,
                  },
                },
              }}
            >
              <InnerPost>
                <PostWrapper className="d-flex justify-content-center">
                  <PostContent
                    variants={{
                      rest: {
                        backgroundColor: 'transparent',

                        boxShadow: '0 0 10px 0 rgba(0,0,0,0.3)',
                      },
                      hover: {
                        boxShadow: '0 0 10px 0 rgba(0,0,0,0.3)',
                        backgroundColor: '#ffffff',
                        transition: {
                          duration: 0.15,
                          type: 'spring',
                        },
                      },
                    }}
                  >
                    <div className="pt-3">
                      <h2 class="font-weight-xl font-size-ms">
                        {parse(singleCase.title)}
                      </h2>
                    </div>
                    <div className="d-flex pt-2 justify-content-end">
                      <ButtonMinimal to={singleCase.path} icon="arrow">
                        <p>Check de mogelijkheden</p>
                      </ButtonMinimal>
                    </div>
                  </PostContent>
                </PostWrapper>
              </InnerPost>
            </Post>
          ))}
        </div>
      </div>
    </StyledCases>
  )
}

export default Projects
